"use client";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { exitB2b, selectEntered } from "store/sessionStorage/slices/b2bSlice";

export default function Template({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const dispatch = useDispatch();
  const entered = useSelector(selectEntered);

  useEffect(() => {
    if (entered) {
      dispatch(exitB2b());
    }
  }, [dispatch]);

  return children;
}
